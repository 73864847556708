import React from 'react';

import IconsSvg from '../../common/IconsSvg/IconsSvg';
import Title from '../../common/Title';

import * as cls from './ModelList.module.scss';

const IconDetails = IconsSvg['ArrowOpen'];

export default function ModelList({ title, models }) {
  if (models.length === 0) return null;

  return (
    <div>
      <Title type='h3' className={cls.title}>
        {title}
      </Title>
      <ul className={cls.list}>
        {models.map((item, i) => (
          <li key={i}>
            <span>{item.title}</span>
            <a className={cls.detailsLink} href={item.uri}>
              <span>Details</span>
              <IconDetails />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
