import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Title from '../Title';
import Arrow from '../../../images/icons/inline/arrow.svg';

import * as cls from './ArticleThumbnail.module.scss';

export default function ArticleThumbnail({ article }) {
  const linkAttributes = article.link.startsWith('http')
    ? { target: '_blank', rel: 'noreferrer' }
    : {};

  return (
    <div className={cls.itemWrap}>
      {article.img && (
        <div
          className={cls.img}
          dangerouslySetInnerHTML={{
            __html: article.img
          }}
        />
      )}
      {article.featuredImage && (
        <GatsbyImage
          className={cls.img}
          image={getImage(article.featuredImage.node.localFile)}
          alt={article.title || 'Post image'}
        />
      )}
      <Title type='h3'>{article.title}</Title>
      <div className={cls.content}>
        <div
          dangerouslySetInnerHTML={{
            __html: article.content
          }}
        />
        <a className={cls.continueLink} href={article.link} {...linkAttributes}>
          <span>{article.linkText ?? 'Read more'}</span>
          <Arrow className={cls.arrow} />
        </a>
      </div>
    </div>
  );
}
