import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Section from '../common/Section';
import Title from '../common/Title';
import Text from '../common/Text';
import Button from '../common/Button';
import { PORTAL_LINK } from '../../constants';

import * as cls from './Promo.module.scss';

export default function Promo({ className = '', data }) {
  if (!data) return null;

  const buttons = data.querySelectorAll('.promo-buttons');

  return (
    <Section className={`${cls.promo} ${className}`}>
      <div className={cls.textBlock}>
        <div className='visually-hidden'>
          {data.querySelector('#promo-title')?.text}
        </div>
        <Title type='h1'>{data.querySelector('#promo-title')?.text}</Title>
        <Text size='big'>{data.querySelector('#promo-text')?.text}</Text>
        <Button className={cls.btn} href={`${PORTAL_LINK}/signup`} target='_blank'>
          {buttons[0]?.text}
        </Button>
      </div>
      <div className={cls.img}>
        <StaticImage
          src='../../images/content/image.png'
          alt='land'
          placeholder='none'
        />
      </div>
    </Section>
  );
}
