import * as React from 'react';

import Layout from '../components/Layout';
import Landing from '../components/Landing';

const IndexPage = () => {
  return (
    <Layout>
      <Landing />
    </Layout>
  );
};

export default IndexPage;
