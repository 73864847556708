import React from 'react';
import Section from '../common/Section';
import Title from '../common/Title';
import ArticleThumbnail from '../common/ArticleThumbnail';

import * as cls from './News.module.scss';

export default function News({ data }) {
  if (!data) return null;

  return (
    <Section id='news' className={cls.wrapper}>
      <Title type='h2'>Last update</Title>
      <div className={cls.news}>
      {data.map(news => {
        const newsData = {
          featuredImage: news.featuredImage,
          title: news.title,
          content: news.excerpt,
          link: news.uri
        };

        return <ArticleThumbnail article={newsData} key={newsData.link} />;
      })}
      </div>
    </Section>
  );
}
