import React, { Fragment } from 'react';
import Section from '../common/Section';
import Title from '../common/Title';
import Card from '../common/Card';
import IconsSvg from '../common/IconsSvg';

import * as cls from './Tools.module.scss';

export default function Tools({ data }) {
  if (!data) return null;
  const toolItems = data.querySelectorAll('.tools-item')
  
  return (
    <Section className={cls.tools} id='tools__block'>
      <Title type='h2'>{data.querySelector('#tools-title')?.text}</Title>
      <div className={cls.cards}>
        {toolItems.map((item, id) => (
          <Fragment key={id}>
            <Card
              className={cls.card}
              Icon={IconsSvg[item.querySelector('.tools-item-icon')?.text]}
              title={item.querySelector('.tools-item-title')?.text}
              label={item.querySelector('.tools-item-icon')?.text}
            >
              {item.querySelector('.tools-item-text').text}
            </Card>
            {id < toolItems.length - 1 && <div className={cls.arrow} />}
          </Fragment>
        ))}
      </div>
    </Section>
  );
}
