import React, { useMemo, useCallback } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { MODEL_TYPES } from '../../constants';
import Section from '../common/Section';
import Title from '../common/Title';
import Button from '../common/Button/Button';
import ModelList from './ModelList';

import * as cls from './DataModels.module.scss';
import * as clsGlobal from '../../theme/global.module.scss';

export default function DataModels({ modelList, data }) {
  const filterModelsByType = useCallback(
    modelType => {
      return modelList.filter(({ tags }) =>
        tags.nodes.some(({ name }) => name === modelType)
      );
    },
    [modelList]
  );

  const {agricultureModels, forestryModels, generalModels} = useMemo(() => {
    return {
      agricultureModels: filterModelsByType(MODEL_TYPES.agriculture),
      forestryModels: filterModelsByType(MODEL_TYPES.forestry),
      generalModels: filterModelsByType(MODEL_TYPES.general)
    };
  }, [filterModelsByType]);
  
  if (!modelList) return null;

  return (
    <Section className={cls.models} id='analytics__block'>
      <div>
        <Title type='h2'>{data.querySelector('#models-title')?.text}</Title>
        <div className={cls.modelItems}>
          <ModelList
            title={MODEL_TYPES.agriculture}
            models={agricultureModels}
          />
          <ModelList
            title={MODEL_TYPES.forestry}
            models={forestryModels}
          />
          <ModelList
            title={MODEL_TYPES.general}
            models={generalModels}
          />
        </div>
        <Button href='/blog'>
          {data.querySelector('#models-button')?.text}
        </Button>
      </div>
      <div className={`${cls.img} ${clsGlobal.hideformobile}`}>
        <StaticImage
          src='../../images/content/tablet.png'
          alt='tablet'
          placeholder='soilmate app'
        />
      </div>
    </Section>
  );
}
