import React from 'react';

import Title from '../Title';
import Text from '../Text';

import * as cls from './Card.module.scss';

export default function Card({
  children,
  className = '',
  Icon = null,
  label = '',
  title = '',
  number = null,
  size = 'base' // base | small
}) {
  return (
    <div className={`${cls.cardsItem} ${cls[size]} ${className}`}>
      {Icon && (
        <div className={cls.cardIcon}>
          <Icon role='img' width='60px' height='60px' aria-label={label} />
        </div>
      )}

      <div>
        <Title type='h3'>
          {!!number && <span className={cls.number}>{number}</span>}
          {title}
        </Title>
        <Text>{children}</Text>
      </div>
    </div>
  );
}
