import React from 'react';

import * as cls from './Title.module.scss';

export default function Title({
  children,
  className = '',
  type = 'h1', // h1 | h2 | h3 | h4 | h5
  show = 'all', // all | mobile | desktop
  ...props
}) {
  const RootTag = type;

  return (
    <RootTag
      className={`${cls.title} ${cls[type]} ${cls[show]} ${className}`}
      {...props}
    >
      {children}
    </RootTag>
  );
}
