import React from 'react';

import * as cls from './Input.module.scss';

export default function Input({
  className = '',
  required = false,
  id = '',
  error = false,
  label,
  onChange = () => {},
  ...props
}) {
  const inputId = id || Math.random();

  return (
    <div
      className={`
      ${cls.holder}
      ${required ? cls.holderRequired : ''}
      ${className}
      `}
    >
      {!!label && <label className={cls.label} htmlFor={inputId}>{label}</label>}
      <input
        className={`${cls.input} ${error ? cls.error : ''}`}
        id={inputId}
        onChange={onChange}
        {...props}
      />
    </div>
  );
}
