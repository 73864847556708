import React from 'react';

import * as cls from './Snackbar.module.scss';

export default function Snackbar({
  className = '',
  text,
  show = false,
  error = false
}) {
  return (
    <div
      id='snackbar'
      className={`
        ${cls.snackbar}
        ${show ? cls.show : ''}
        ${error ? cls.error : ''}
        ${className}`}
    >
      {text}
    </div>
  );
}
