import React from 'react';

import Section from '../common/Section';
import Title from '../common/Title';
import ArticleThumbnail from '../common/ArticleThumbnail';

import * as cls from './UseCases.module.scss';

export default function UseCases({ className = '', data }) {
  if (!data) return null;

  return (
    <Section className={`${cls.wrapper} ${className}`} id='use_cases'>
      <Title type='h2'>{data.querySelector('#use_cases-title')?.text}</Title>
      <div className={cls.useCases}>
        {data.querySelectorAll('.use_cases-item').map(item => {
          const link = item.querySelector('.use_cases-item-link');
          const articleData = {
            img: item.querySelector('.use_cases-item-img'),
            title: item.querySelector('.use_cases-item-title')?.text,
            content: item.querySelector('.use_cases-item-text')?.text,
            link: link?.getAttribute('href'),
            linkText: link?.text
          };

          return (
            <ArticleThumbnail article={articleData} key={articleData.link} />
          );
        })}
      </div>
    </Section>
  );
}
