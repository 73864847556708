import React from 'react';

import * as cls from './Section.module.scss';

export default function Section({
  children,
  className = '',
  background = false,
  ...props
}) {
  return (
    <>
      {background ? (
        <div className={cls.wrapper}>
          <section className={className} {...props}>
            {children}
          </section>
        </div>
      ) : (
        <section className={className} {...props}>
          {children}
        </section>
      )}
    </>
  );
}
