import React, { useState } from 'react';
import { sendForm } from 'emailjs-com';

import Section from '../common/Section';
import Title from '../common/Title';
import Input from '../common/Input';
import Button from '../common/Button';
import Snackbar from '../common/Snackbar';

import * as cls from './ContactUs.module.scss';

export default function ContactUs({ data }) {
  const [error, setError] = useState({
    email: false,
    message: false
  });
  const [dataSnackbar, setDataSnackbar] = useState({
    show: false,
    text: '',
    error: false
  });

  const validation = form => {
    const emailError = !form.email.value.trim();
    const messageError = !form.message.value.trim();

    setError({
      email: emailError,
      message: messageError
    });

    return !emailError && !messageError;
  };

  const handleInputChange = e => {
    setError({ ...error, [e.target.name]: false });
  };

  const sendEmail = e => {
    e.preventDefault();

    if (!validation(e.target)) return;

    const showSnack = (error = false) => {
      setDataSnackbar({ show: true, text: 'Message sent', error });
      setTimeout(() => {
        setDataSnackbar({ show: false, text: '', error: false });
      }, 3000);
    };

    sendForm(
      process.env.GATSBY_EMAILJS_SERVICE_ID,
      process.env.GATSBY_EMAILJS_TEMPLATE_ID,
      e.target,
      process.env.GATSBY_EMAILJS_USER_ID
    ).then(
      result => {
        showSnack(true);
      },
      error => {
        showSnack(false);
      }
    );
  };

  if (!data) return null;

  return (
    <Section className={cls.contactUs} id='contact_us' background={true}>
      <Title className={cls.title} type='h2'>
        {data.querySelector('#contact_us-title')?.text}
      </Title>

      <form className={cls.form} id='contact-form' onSubmit={sendEmail}>
        <Input
          className={cls.input}
          id='email'
          name='email'
          type='text'
          placeholder={data.querySelector('#contact_us-email')?.text}
          required={true}
          error={error.email}
          onChange={handleInputChange}
        />
        <Input
          className={cls.input}
          id='message'
          name='message'
          type='text'
          placeholder={data.querySelector('#contact_us-message')?.text}
          required={true}
          error={error.message}
          onChange={handleInputChange}
        />

        <Button className={cls.btn} type='submit'>
          {data.querySelector('#contact_us-button')?.text}
        </Button>
        <Snackbar
          text={dataSnackbar.text}
          error={dataSnackbar.error}
          show={dataSnackbar.show}
        />
      </form>
    </Section>
  );
}
