import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { parse } from 'node-html-parser';
import { Helmet } from 'react-helmet';

import Promo from '../Promo';
import Tools from '../Tools';
import DataModels from '../DataModels';
import Benefits from '../Benefits';
import ContactUs from '../ContactUs';
import UseCases from '../UseCases';
import News from '../News';

export default function Landing() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
      wpPage(slug: { eq: "landing" }) {
        content
      }
      uncategorizedPosts: allWpPost(
        sort: { fields: [date] }
        filter: {
          categories: { nodes: { elemMatch: { name: { ne: "news" } } } }
        }
      ) {
        nodes {
          title
          slug
          uri
          tags {
            nodes {
              name
            }
          }
        }
      }
      newsPosts: allWpPost(
        sort: { fields: [date], order: DESC }
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "news" } } } }
        }
        limit: 3
      ) {
        nodes {
          title
          slug
          uri
          excerpt
          tags {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 360
                    placeholder: NONE
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = parse(data.wpPage.content);
  
  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name='Description' content={data.site.siteMetadata.description} />
      </Helmet>

      <Promo data={content.querySelector('#promo')} />
      <DataModels
        data={content.querySelector('#models')}
        modelList={data.uncategorizedPosts.nodes}
      />
      <Benefits data={content.querySelector('#benefits')} />
      <Tools data={content.querySelector('#tools')} />
      <News data={data.newsPosts.nodes} />
      <UseCases data={content.querySelector('#use_cases')} />
      <ContactUs data={content.querySelector('#contact_us')} />
    </>
  );
}
