import React from 'react';

import Section from '../common/Section';
import Title from '../common/Title';
import Card from '../common/Card';
import Button from '../common/Button';
import { PORTAL_LINK } from '../../constants';

import * as cls from './Benefits.module.scss';

export default function Benefits({ data }) {
  if (!data) return null;

  return (
    <Section className={cls.benefits} id='benefits__block'>
      <div className={cls.benefits__header}>
        <Title type='h2'>{data.querySelector('#benefits-title')?.text}</Title>
        <Button className={cls.btn} href={PORTAL_LINK} target="_blank">
          {data.querySelector('#benefits-button')?.text}
        </Button>
      </div>
      <div className={cls.benefits__items}>
        {data.querySelectorAll('.benefits-item').map((item, id) => (
          <Card
            key={id}
            className={cls.card}
            number={item.querySelector('.benefits-item-number')?.text}
            title={item.querySelector('.benefits-item-title')?.text}
          >
            {item.querySelector('.benefits-item-text')?.text}
          </Card>
        ))}
      </div>
    </Section>
  );
}
